import React from 'react';
import '../../App.css';
import CSS from 'csstype';
import adelaSunniFront from './images/adelaSunnyFront.jpg';
import adelaSunnyBack from './images/adelaSunnyBack.jpg';




function Partners() {
  return (
    <div className='topOffset boottomOffset'>
      <ul>
        <li>Флавия Фарма гр. София</li>
        <li>Фрам гр. Варна</li>
        <li>Магазин за натурална козметика в хотел Диана Мар, ул. Освобождение № 3, гр. Павел Баня</li>
        <li>АРТ ЪП, гр. София</li>
        <li>Студио за красота ДМ гр. София</li>
        <li>Фермерски Пазаръ - храни и занаяти</li>
        <li>Техномаркет България</li>
        <li>ЕКОМ БГ Брандс</li>
        <li>Апифарма гр. София</li>
      </ul>
    </div>
  );
}

export default Partners;
