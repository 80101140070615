import '../../App.css';
import texts from './text/texst.json';
import shopIcon from './images/shopIcon.png';

interface Propes {
  itemId: string | null
}

function ShopItem(props: Propes) {
  // const { itemId } = useParams();
  console.log("**props***", props.itemId)

  if(props.itemId === '3856000' ||
     props.itemId === '3856001' ||
     props.itemId === '3856002' ||
    //  props.itemId === '3856003' || for floral water
     props.itemId === '3856004' ||
     props.itemId === '3856005' ||
     props.itemId === '3856006' ||
     props.itemId === '3856007' ||
     props.itemId === '3856008' ||
     props.itemId === '3856009' ||
     props.itemId === '3856010' ||
     props.itemId === '3856110' ||
     props.itemId === '3856111' ||
     props.itemId === '3856112' ||
     props.itemId === '3856113' ||
     props.itemId === '3856114' 
  ){
    const img = require('./images/id' + props.itemId + '.jpg');
    let shopTextElement;
    let backgroundImage = <img className='productImg productImgBackground' src={img}/>;
    // if( props.itemId === '3856002' || props.itemId === '3856001' || props.itemId === '3856000'){
    //   shopTextElement = <a href={texts[props.itemId].link} target="_blank" className='productBuyLinkText'>Продукта от серията може да поръчате от <span className='productBuyLinkTextActive'>тук</span></a>
    //   backgroundImage = <a href={texts[props.itemId].link} target="_blank"><img className='productImg productImgBackground' src={img}/><img className='productImg productShopImg' src={shopIcon}/></a>
    // } 

    return (
      <div className='topOffset' >
        <div className='productContainer'>
          <div className='productImdAndTitle'>
            <div className='productImgContianer'>
            {backgroundImage}
            </div>
            <div dangerouslySetInnerHTML={{ __html: texts[props.itemId].title }}></div>
            <div dangerouslySetInnerHTML={{ __html: texts[props.itemId].price }}></div>
            {/* <div className='order'>За поръчка ни пишете на: bramitrade@gmail.com</div> */}
            <div className='landscepaeHidenElement'>{shopTextElement}</div>
            <br></br>
          </div>
          <div className='productText' dangerouslySetInnerHTML={{ __html: texts[props.itemId].descrition }}></div>
          <div className='productText portraitHidenElement'>{shopTextElement}</div>
        </div>
        <div className='bottomMarginElement'></div>
      </div>
    );
  } else {
    return (
      <div> Продуктът не може да бъде намерен.</div>
    );
  }
  
}


export default ShopItem;
