import React from 'react';
import '../../App.css';
import CSS from 'csstype';
import { Link } from 'react-router-dom';
import FloatingIcons from '../FloatingIcons/FloatingIcons';


const hederStyle: CSS.Properties = {

    borderBottom: '1px solid black',
    color: 'white',
    fontFamily: 'Arial',
    paddingTop: '2px',
    position: 'fixed',
    top: '0px',
    zIndex: '100',
};

const hederText: CSS.Properties = {
  display: 'flex',
  float: 'left',
  paddingLeft: '20px',
  marginTop: '16px',
  color: 'white',
  textDecoration: 'none',
  fontFamily: 'Roboto-Bold',
}
const hederTextContainer: CSS.Properties = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0%, -70%)',
  fontSize: '16px',
}



function Header() {
  return (
    
    <div className="heder" style={hederStyle}>
      <div style={hederTextContainer}>
        <Link className='hederFontSize' to="/?home=true" style={hederText}>НАЧАЛО</Link>
        <Link className='hederFontSize' to="/?about=true" style={hederText}>ЗА НАС</Link>
        <Link className='hederFontSize' to="/?shop=true" style={hederText}>ПРОДУКТИ</Link>
        <Link className='hederFontSize' to="/?partners=true" style={hederText}>ПАРТНЬОРИ</Link>
      </div>
    </div>
  );
}

export default Header;
