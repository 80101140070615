import React from 'react';
import '../../App.css';
import CSS from 'csstype';
import callUs from './images/callUs.png';
import fbIcon from './images/fbIcon.png';

const icosStyle: CSS.Properties = {
  position: 'relative',
  display: 'flex',
  float: 'right',
  width: '50px',
  margin: '6px',
  border: '4px solid white',
  borderRadius: '100%',
}




function FloatingIcons() {
  return (
    <div className='floatingImages'>
     <a href={getFacebookLink()}>
        <img src={fbIcon} style={icosStyle}/>
      </a>
      <a href={getPhoneCall()}>
        <img src={callUs} style={icosStyle}/>
      </a>
    </div>
  );
}

function getPhoneCall(): string{
  if(detectMob()){
    return 'tel:+359889342781';
  } else {
    return '/?about=true';
  }
}

function getFacebookLink(): string 
{
  if(detectMob()){
    // return "fb://facewebmodal/f?href=fb://profile/100084022787808";
    return "fb://profile/100084022787808";
  } else {
    return "https://www.facebook.com/Bramitrade";
  }
}

function detectMob(): boolean {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

export default FloatingIcons;
