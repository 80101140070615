import React from 'react';
import '../../App.css';
import CSS from 'csstype';
import adelaSunniFront from './images/adelaSunnyFront.jpg';
import adelaSunnyBack from './images/adelaSunnyBack.jpg';




function AboutPage() {
  return (
    <div className='topOffset boottomOffset'>
      <div>Ние сме компания, обединена в мисията "Здраве и красота с шафран". Шафранът, който влагаме в нашите продукти, отговаря на 1-во качество по ISO3632.</div>
      <div>Предлагаме продукти за лична хигиена и грижа с шафран. Това са категория козметични продукти, които разработваме в съответствие с регламент ЕС 1223/2009 и свързаните с него. Екипът ни е обучен в правилата по добри производствени практики, за което сме сертифицирани по ISO 22716 - GMP Cosmetics.</div>
      <br></br>
      <div>✔ Създадохме серия за ежедневна грижа и хигиена с шафран, която нарекохме "Saffron Adela Sunny". Тя е с подбрани, съчетани съставки за нежно, хидратиращо почистващо действие.</div>
      <div>- Част от тях принадлежащи към групата "Ново поколение" мултифункционални без корен PEG повърхностно активни вещества. Основа на суровини от естествен произход, специално посочени в качествени формули, които изискват добро почистване, отлична биосъвместимост с дермата, подобрен вискозитет и повишена плътност на пяната.</div>
      <div>- Част от тях с изключително меки, едни от най-нежните почистващи агенти, които ги правят идеални за всички почистващи и пенещи се продукти, особено за тези насочени към реактивна или чувствителна кожа.</div>
      <div>✔ Серията е прекрасен помощник в грижата за кожата и косата, благодарение на противовъзпалителните, антиоксидантните, антисептични и регенериращи свойства на шафрана Crocus sativus, в комбинация с растителни екстракти и от други полезни растения.</div>
      <div>✔ Продуктите могат да се използват ежедневно при разумни количества от мъже, жени и деца над 3 годишна възраст.</div>
      <div>✔ Не съдържат компоненти класифицирани като канцерогени, мутагени и токсични за репродукцията.</div>
      <div>✔ Не са тествани върху опитни животни.</div>
      <div>✔ 100% чист шафран от нашите градини.</div>
      <br></br>
      <br></br>
      <div>Контакти:</div>
      <div><i>Гр. София</i></div>
      <div><i>Кривина, ул. Демокрация 13</i></div>
      <div><i>+359 88 934 2781</i></div>
      <br></br>
      <div><i>info@brami-trade.com</i></div>
      <div><i>sales@brami-trade.com</i></div>
      <div className='bottomMarginElement'></div>
    </div>
  );
}

export default AboutPage;
