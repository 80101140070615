import '../../App.css';
import CSS from 'csstype';
import cover0 from './images/cover_0.jpg';
import cover1 from './images/cover_1.jpg';
import cover2 from './images/cover_2.jpg';
import cover3 from './images/cover_3.jpg';
import { useNavigate } from 'react-router-dom';


// const underConstructionText: CSS.Properties = {
//   color: 'red',
//   fontSize: '40px',
//   fontFamily: 'Arial',
//   textAlign: 'center',
// };

// const presentToYou: CSS.Properties = {
//   fontSize: '30px',
// 	fontFamily: 'Arial',
// 	textAlign: 'center',
// }

const imagesConatiner: CSS.Properties = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '90%',
  maxWidth: '980px',
  cursor: 'pointer',
  margin: 'auto',

}

// const images: CSS.Properties = {
//   borderRadius: '30px 0 30px 0',
//   width: '-webkit-fill-available',
// }


function HomePage() {
  const navigate = useNavigate();
  return (
    <div>
      <div className='homeTextStyle topOffset' >
        <h2 className=''><b>ДОБРЕ ДОШЛИ В СТРАНИЦАТА НА BRAMI TRADE</b></h2>
        <h4 className='homesubTitle'>ОСНОВАНА ПРЕЗ 2021 ГОДИНА, ЗА ДА СЪЗДАДЕ И ПРЕДЛОЖИ ЗДРАВЕ ОТ ПРИРОДАТА</h4>
        {/* </div>

        <div className='homeTextStyle boottomOffset'> */}
        <div><b>Шафран и продукти с шафран, добит в България.</b></div>
        <ul>
          <li>За всяка луковичка сме положили внимателни грижи, за да разцъфти красив минзухар с най-високо качество.</li>
          <li>Всеки цвят е ръчно набран рано сутрин, когато силата му е най-мощна.</li>
          <li>Всяка стигма е изсушена при подходящи температурни условия, за да запази полезните си съставки.</li>
          <li>Всеки добив е оценен и окачествен, и тогава червената енергия, се влива в душата на продуктите ни.</li>
          <br/>
          <li>Шафранът известен като червено злато и слънчева подправка, има широк спектър от ползи за цялостно здраве, косата и кожата. Удивителните ползи от употребата му са:</li>
          <br/> 
          <li>Почиства кръвта от токсини и алергени.</li>
          <li>Бори се с различни болести.</li>
          <li>Подобрява зрението.</li>
          <li>Стимулира храносмилането.</li>
          <li>Полезен е по време на бременност.</li>
          <li>Нормализира налягането.</li>
          <li>Укрепва съдовете.</li>
          <li>Има бактерицидни свойства.</li>
          <li>Подобрява имунитета.</li>
          <li>Успокоява нервната система.</li>
          <li>Подобрява състоянието на кожата.</li>
          <li>Изсветлява кожата, прави я сияйна.</li>
          <li>Лекува суха кожа.</li>
          <li>Предотвратява косопад.</li>
          <br/>
          <li>Crocus sativus, съдържа повече от 150 летливи, нелетливи и ароматизиращи съединения:</li>
          етерично масло, каротеноиди, глюкозиди, кроцин, пикрокроцин, сафранал, терпени, алкохоли и техните естери, мазнини; минерали, флавоноиди, пектини, танини, сапонини, магнезий, селен, цинк, манган, желязо, витамин С, витамини от група В, витамин А.
          <br/>
          <li>Шафрана има свойството да защитава и хидратира кожата в дълбочина, прави я здрава, защитава я срещу оксидативния стрес. Благодарение на букета от витамини и минерали, шафрана действа антиоксидантно. А когато свободните радикали се елиминират, различни вредни влияния не могат да доведат до създаване на негативни натрупвания в кожните клетки. Когато кожните клетки не са задръстени с оксиданти, те могат да функционират нормално и да се самовъзстановяват. Благодарение на естествените стимулиращи и антибактериални съставки, поддържа баланса на капилярната циркулация, което е важно условие за здрава кожа и коса. Шафрана има противовъзпалително действие, поради което предпазва кожата от зачервяване и раздразнение. Той е силен антиоксидант заради каротеноидите, които съдържа. Те прочистват кожата от мръсотиите, попаднали през порите, правят я по-здрава и млада.</li>
        </ul>
        <h2 className='meetUs'>Запознайте се с козметичните продукти на нашата слънчева чистота от серия с шафран.</h2>
        <div>
          <div id='cf4a' onClick={()=>{navigate('/?shop=true');}} style={imagesConatiner} >
          <img className='homeImage' src={cover0}></img>
          <img className='homeImage' src={cover1}></img>
          <img className='homeImage' src={cover2}></img>
          <img className='homeImage' src={cover3}></img>
        </div>
      </div>
      </div>
    </div>
  );
}

export default HomePage;
