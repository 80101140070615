import React from 'react';
import '../../App.css';
import CSS from 'csstype';


const futter: CSS.Properties = {
  borderTop: '1px solid black',
  color: 'white',
  fontSize: '18px',
  fontFamily: 'Arial',
  paddingTop: '2px',
  position: 'fixed',
  bottom: '0px',
  zIndex: '100',
};

const emailText: CSS.Properties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '200px',
  fontFamily: 'Roboto-Regular',
}


function Futter() {
  return (
    <div className="futter" style={futter}>
      <div style={emailText}>sales@brami-trade.com</div>
    </div>
    
  );
}

export default Futter;
