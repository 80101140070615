
import './App.css';
import Header from './Components/Header/Header';
import Futter from './Components/Futter/Futter';
import HomePage from './Components/HomePage/HomePage';
import {Navigate, Route, Routes} from'react-router-dom';
import AboutPage from './Components/AboutPage/AboutPage';
import ShopPage from './Components/ShopPage/ShopPage';
import { useSearchParams } from 'react-router-dom'
import ShopItem from './Components/ShopItem/ShopItem';
import FloatingIcons from './Components/FloatingIcons/FloatingIcons';
import Partners from './Components/Partners/Partners';



function App() {
  const [searchParams, setSearchParams] = useSearchParams();

  let state: 'home' | 'about' | 'shop' | 'shopItem' = 'home';

  const itemId: string | null = searchParams.get('productID');

  // console.log("****11*", itemId)

  let component

  // console.log("****",window.location.pathname + window.location.search)
  

  switch(window.location.pathname + window.location.search){
    case "/?shop=true":
      component = <ShopPage />;
      
      break;
    case "/":
    case "/?home=true":
      component = <HomePage />;
    break;
    case "/?about=true":
      component = <AboutPage />;
    break;
    case "/?partners=true":
      component = <Partners />;
    break;
    default:
      if(itemId){
        component = <ShopItem itemId={itemId}/>;
      }
    break;
    // case "/shopItem":
    //   component = <ShopItem itemId={itemId}/>;
    // break;
  }

  return (
    <>
    {/* <div className="App"> */}



      <Header/>
      {component}
      {/* {itemId && <Navigate to={'/shopItem/' + itemId} />}
      <Routes>
        <Route path="/home" element={<HomePage/>}/>
        <Route path="*" element={<ShopPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/shop" element={<ShopPage/>}/>
        <Route path="/shopItem/:itemId" element={<ShopItem/>}/>
      </Routes> */}
      { <div className='boottomGradientMask'></div> }
      <FloatingIcons/>
      <Futter/>


    </>
  );  
}

export default App;
